<template>
  <v-btn
    color="#5cb7b1"
    dark
    tabindex="-1"
    @click="validatePayments"
  >
    Рассчитать
  </v-btn>
</template>
<script>
import {validatePayments} from './payments'
export default {
  methods:{
    validatePayments
  }
}
</script>